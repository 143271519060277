<template>
  <div>
    <Grid />
  </div>
</template>
<script>
import Grid from "@/views/main/views/promotions/components/Grid.vue";

export default {
  name: "PromotionList",
  components: {
    Grid,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
  
  
  